<template>
  <div id="app">
    <HeaderMenu/>
      <Advertisement />
      <router-view></router-view>
    <FooterMenu />
  </div>
</template>

<script>
import Advertisement from './components/Advertisement.vue'
import HeaderMenu from './components/HeaderMenu.vue'
import FooterMenu from './components/FooterMenu.vue'
export default {
  name: 'App',
  components: {
    Advertisement,
    HeaderMenu,
    FooterMenu
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
</style>
