<template>
    <div class="advertisement_wrap" @click="openUrl" v-if="isShow&&show">
      <div class="close_wrap" @click="close"></div>
      <p><i class="icon_adv" />普惠产品服务专区</p>
      <p class="icon_adv_text">电子保函与经营性融资服务</p>
    </div>
</template>
<script>
export default {
    data(){
        return {
            show:true
        }
    },
    computed:{
        isShow(){
            return this.$route.path !== '/finance'
        }
    },  

    methods:{
        close(){
            this.show = false
        },

        openUrl(){
            this.$router.push({
                path:'/finance'
            })
        }
    }
}
</script>

<style scoped>
.advertisement_wrap{
    padding: 24px 0 0 43px;
    box-sizing: border-box;

    width: 310px;
    height: 115px;

    position: relative;

    background-image: url('../assets/advertisement_bg.png');
    background-size: 100% 100%;

    position: fixed;
    bottom: 30px;
    left: 40px;
    z-index: 999;

    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    cursor: pointer;
}

.close_wrap{
    z-index: 999;

    background-image: url('../assets/close_icon.png');
    background-size: 100% 100%;

    position: absolute;
    right: -30px;
    top: -20px;

    width: 32px;
    height: 33px;

    cursor: pointer;
}

.icon_adv{
    width: 30px;
    height: 32px;

    background-image: url('../assets/icon_adv.png');
    background-size: 100% 100%;
}

.icon_adv_text{
    margin-top: 18px;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
}
</style>