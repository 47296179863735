import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export const constantRoutes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/newHome")
  }, {
    path: '/supplyChain',
    name: 'supplyChain',
    component: () => import("@/views/supplyChain")
  }, {
    path: '/biddingProcurement',
    name: 'biddingProcurement',
    component: () => import("@/views/biddingProcurement")
  }, {
    path: '/solution',
    name: 'solution',
    component: () => import("@/views/solution.vue")
  }, {
    path: '/control',
    name: 'control',
    component: () => import('@/views/control.vue')
  }, {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news.vue')
  }, {
    path: '/aboutMy',
    name: 'aboutMy',
    component: () => import('@/views/aboutMy.vue')
  }, {
    path: '/newInfo',
    name: 'newInfo',
    component: () => import('@/views/newInfo.vue')
  }, {
    path: '/bill',
    name: 'bill',
    component: () => import('@/views/bill.vue')
  }, {
    path: '/tray',
    name: 'tray',
    component: () => import('@/views/tray.vue')
  },{
    path:'/Inclusive',
    name:'Inclusive',
    component:()=>import('@/views/Inclusive.vue')
  },{
    path:'/newHome',
    name:'newHome',
    component:()=>import('@/views/newHome.vue')
  },{
    path:'/finance',
    name:'finance',
    component:()=>import('@/views/finance.vue')
  }
];

export const asyncRoutes = [

];
export const routeList = JSON.parse(JSON.stringify(asyncRoutes));
const createRouter = () =>
  new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  });

const router = createRouter();

export default router;
