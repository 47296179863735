<template>
    <div class="foot_wrap">
        <div class="list_wrap">
            <div class="list_item cursor" v-for="item in list" @click="toPath(item.path)">{{
                item.name
            }}</div>
        </div>

        <div class="line"></div>

        <!-- 地址 -->

        <div class="foot_b_wrap">
            <div>
                <p class="name">华融金睿（北京）科技有限公司</p>
                <p class="address">客服电话：400-186-6655</p>
                <p class="address">服务时间：工作日9:00-18:00</p>
                <p class="address">公司地址：北京市朝阳区光华路15号院1号楼17层1715室</p>
            </div>

            <div class="foot_right_wrap">
                <!-- <p class="english">HUARONGJINRUI</p>
                <div class="foot_logo"></div> -->
                <p>关注我们</p>
                <img src="../assets/ewm.png">
            </div>
        </div>

        <p class="copyright">
            <a href="https://xyt.xcc.cn/getpcInfo?sn=1744543600961609728&language=CN&certType=8&url=*.hrjrkj.com" target="_blank" style="position: relative;vertical-align: middle; display: inline-block;height: 38px;">
  <div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div><embed src="https://program.xinchacha.com/web/1744543600961609728=*.hrjrkj.com.svg" width="103" height="38" type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/"/></a>
  版权所有：华融金睿（北京）科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;备案/许可证编号：京ICP备2022007807号
  
</p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list:[{
                name:'供应链金融',
                path:'/supplyChain'
            },{
                name:'数智招采',
                path:'/biddingProcurement'
            },{
                name:'解决方案',
                path:'/solution'
            },{
                name:'风险管控',
                path:'/control'
            },{
                name:'新闻中心',
                path:'/news'
            },{
                name:'关于我们',
                path:'/aboutMy'
            }]
        }
    },
    methods:{
        toPath(path){
        this.$router.push({
          path
        })
      }
    }
}
</script>

<style scoped>
.foot_wrap{
    width: 100%;
    height: 386px;
    background: #3E424C;

    padding: 68px 94px 0 92px;
    box-sizing: border-box;
}

.list_wrap{
    display: flex;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #DDDDDD;
}

.list_item{
    position: relative;
    padding:0 30px 0 0 ;
}

.list_item::before{
    content: '·';
    position: absolute;
    right: 15px;
    color: #DDDDDD;
}

.list_item:last-child::before{
    content: '';
    position: absolute;
}

.line{
    margin: 31px auto;
    width: 100%;
    height: 1px;
    background: #FFFFFF;
    opacity: 0.2;
}

.english{
    font-size: 120px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-style: italic;
    color: #8E8E8E;
    opacity: 0.27;
}

.foot_b_wrap{
    display: flex;
    justify-content:space-between;
}

.address, .name,.copyright{
    margin: 14px 0 0 0;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.5;

    text-align: left;
}



.foot_logo{
    position: absolute;
    right: 0;
    top: 68px;
    width: 289px;
    height: 59px;
    background-image: url('../assets/foot_logo.png');
    background-size: 100% 100%;
}

.foot_right_wrap{
    position: relative;
    margin: 0 50px 0 0;
    
}

.foot_right_wrap p{
    text-align: center;
    margin: 0px 0 10px;
    color: #fff;
}
.foot_right_wrap img{
    width: 120px;
    height: 120px;
}
.copyright{
    margin: 0;
}

.cursor{
    cursor:pointer;
}

.copyright{
    margin: 24px 0 0 0;
    text-align: center;
}
</style>