import { render, staticRenderFns } from "./Advertisement.vue?vue&type=template&id=69480762&scoped=true&"
import script from "./Advertisement.vue?vue&type=script&lang=js&"
export * from "./Advertisement.vue?vue&type=script&lang=js&"
import style0 from "./Advertisement.vue?vue&type=style&index=0&id=69480762&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69480762",
  null
  
)

export default component.exports