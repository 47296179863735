<template>
    <div class="headers">
      <div class="header_main">
        <img  class="header_logo" src="../assets/logo.png" />

        <div class="menu_wrap">
          <div class="menu_item" v-for="item in menuList" :class="(pathName===item.path?'borderShow':'')"  @click="toPath(item.path)">
            {{item.name}}
          </div>
        </div>

        <span class="header_phone">
          <i class="el-icon-phone-outline"></i>
          400-186-6655
        </span>
      </div>

    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        pathName:'/',
        menuList:[
          {
            name:'首页',
            path:'/'
          },
          {
            name:'供应链金融',
            path:'/supplyChain'
          },{
            name:'普惠金融',
            path:'/finance'
          },
          {
            name:'数智招采',
            path:'/biddingProcurement'
          },
          {
            name:'解决方案',
            path:'/solution'
          },
          {
            name:'风控管理',
            path:'/control'
          },
          {
            name:'新闻中心',
            path:'/news'
          },
          {
            name:'关于我们',
            path:'/aboutMy'
          }
        ]
      }
    },
    watch:{
      $route:{
        handler(val,oldval){
          this.pathName = val.path
        },
        // 深度观察监听
        deep: true
      }
    },

    methods:{
      toPath(path){
        this.$router.push({
          path
        })
      }
    }

  }
  </script>

  <style scoped>
.headers{
  position: fixed;
  top: 0;

  z-index: 999;

  width: 100%;
  height: 100px;
  background: rgba(22, 45, 87, 0.3);

}

.header_logo{
  margin: 0 40px 0 0;
  width: 224px;
  height: 46px;
}

.menu_wrap{
  display: flex;
}
.header_main{
  padding: 27px 0 0 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 1310px;
  margin: 0 auto;
}

.menu_item{
  cursor:pointer;
  margin: 0 10px;
  padding: 10px;
  box-sizing: border-box;

  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
}

.borderShow{
  border-bottom: 2px solid #fff;
}
.header_phone{
  font-size: 20px;
  color: #fff;
}

</style>
  